import React from 'react';
import { Paper, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import useStyles from './style';

const Packages = (props) => {
	const classes = useStyles();
	const { data } = props;	
	return (
		<div className={classes.root}>
			<Paper variant="outlined" elevation={3} className={classes.paper}>
				<div className={classes.paperContainer}>
					<div className={classes.subscriptionAvatar}>
						{data.icon && data.icon}
					</div>
					<div className={classes.subscriptionType}>
						{data.name && data.name}
					</div>
					<div className={classes.subscriptionPrice}>
						{data.price && data.price}
					</div>
					<div className={classes.subscriptionPlan}>
						zl/rok
					</div>
				</div>
				<div className={classes.checkContainer}>
					<FormGroup>
						{data.list.map((item, index) => (
							<FormControlLabel
								control={<Checkbox checked={true} inputProps={{ 'aria-label': 'small' }} size="small" />}
								label={item.name}
								key={index}
							/>
						))}
					</FormGroup>
				</div>
				<div className={classes.checkOut}>
					Sprawdź
				</div>
			</Paper>
		</div>
	);
};

export default Packages;
