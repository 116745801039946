import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Account, Packages } from './components'
import useStyles from './style';
import profile from 'apis/profile';
import { useToasts } from 'react-toast-notifications';
import constants from 'utils/constants'
import { BasicSubscriptionIcon, StandardSubscriptionIcon, PremiumSubscriptionIcon } from 'assets/svg/icons';

const tempCountryList = [
	{ id: 1, name: 'Polska' },
	{ id: 2, name: 'Spain' },
	{ id: 3, name: 'Ukraine' }
]

const tempLanguageList = [
	{ id: 1, name: 'Polsky' },
	{ id: 2, name: 'English' },
	{ id: 3, name: 'Spainish' },
	{ id: 4, name: 'Ukrainian' },
]

const tempProvinceList = [
	{ id: 1, name: 'Polsky Province' },
	{ id: 2, name: 'English Province' },
	{ id: 3, name: 'Spainish Province' },
	{ id: 4, name: 'Ukrainian Province' },
]

const tempPositionList = [
	{ id: 1, name: 'Polsky Position' },
	{ id: 2, name: 'English Position' },
	{ id: 3, name: 'Spainish Position' },
	{ id: 4, name: 'Ukrainian Position' },
]

const basicList = {
	name: 'Basic',
	price: '150',
	icon: <BasicSubscriptionIcon />,
	list: [
	{id: 0, name: 'Lorem ipsum'},
	{id: 1, name: 'Lorem ipsum'},
	{id: 2, name: 'Lorem ipsum'},
]
};

const standardList = {
name: 'Standard',
price: '180',
icon: <StandardSubscriptionIcon />,
list: [
{id: 0, name: 'Lorem ipsum'},
{id: 1, name: 'Lorem ipsum'},
{id: 2, name: 'Lorem ipsum'},
{id: 3, name: 'Lorem ipsum'},
{id: 4, name: 'Lorem ipsum'},
{id: 5, name: 'Lorem ipsum'},
]
};

const premiumList = {
name: 'Premium',
price: '200',
icon: <PremiumSubscriptionIcon />,
list: [
	{id: 0, name: 'Lorem ipsum'},
	{id: 1, name: 'Lorem ipsum'},
	{id: 2, name: 'Lorem ipsum'},
	{id: 3, name: 'Lorem ipsum'},
	{id: 4, name: 'Lorem ipsum'},
	{id: 5, name: 'Lorem ipsum'},
	{id: 6, name: 'Lorem ipsum'},
	{id: 7, name: 'Lorem ipsum'},
	{id: 8, name: 'Lorem ipsum'},
	{id: 9, name: 'Lorem ipsum'},
]
};

const Profile = (props) => {
  const classes = useStyles();
	const { history } = props;
	const { addToast } = useToasts()
	const [progressStatus, setProgressStatus] = useState(false);
	const [countryList, setCountryList] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState('');
	const [languageList, setLanguageList] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState('');
	const [provinceList, setProvinceList] = useState([]);
	const [selectedProvince, setSelectedProvince] = useState('');
	const [positionList, setPositionList] = useState([]);
	const [selectedPosition, setSelectedPosition] = useState('');
	const [input, setInput] = useState({
		firstName: '',
		lastName: '',
		fileName: '',
		uploadFile: {},
		avatarUrl: '',
		city: '',
		postCode: '',
		phoneNumber: '',
		businessPhone: '',
		accountType: '',
		companyName: '',
		companyWebsite: '',
		taxNumber: '',
		companyEmailAddress: '',
		email: '',
		currentPassword: '',
		newPassword: '',
		confirmPassword: '',
	})
	const [error, setError] = useState({});
	const [tryUpdate, setTryUpdate] = useState(false);

	useEffect(() => {
		profile.getInfo()
			.then(response => {
				if(response.code === 401) {
					history.push('/login')
				} else {
					if (response.code === 200) {
						setCountryList(response.data.countries);
						setLanguageList(response.data.languages);
						setProvinceList(response.data.provinces);
						setPositionList(response.data.positions);
						// setCountryList(tempCountryList);
						// setLanguageList(tempLanguageList);
						// setProvinceList(tempProvinceList);
						// setPositionList(tempPositionList);
					}
				}
			})
	}, [])

	useEffect(() => {
		profile.getUserProfile()
			.then(response => {
				if(response.code === 401) {
					history.push('/login')
				} else {
					if (response.code === 200) {
						setSelectedCountry(response.data.selectedCountry)
						setSelectedLanguage(response.data.selectedLanguage)
						setSelectedProvince(response.data.selectedProvince)
						setSelectedPosition(response.data.setSelectedPosition)
						console.log(response.data.user)
						setInput(response.data.user)
					}
				}
			})
	}, [positionList])

	useEffect(() => {
		let arr = JSON.parse(JSON.stringify(error));
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!input["email"] || (input["email"] && !pattern.test(input["email"]))) {
      arr["email"] = constants.ENTER_VALID_EMAIL;
    } else {
      arr["email"] = "";
    }
    if (!input["firstName"]) {
      arr["firstName"] = constants.ENTER_FIRSTNAME;
    } else {
      arr["firstName"] = "";
    }
		if (!input["lastName"]) {
      arr["lastName"] = constants.ENTER_LASTNAME;
    } else {
      arr["lastName"] = "";
    }
		if (!input["currentPassword"] || (input["currentPassword"] && input["currentPassword"].length <= 5)) {
      arr["currentPassword"] = constants.CHECK_VALID_PASSWORD;
    } else {
      arr["currentPassword"] = "";
    }
		if (!input["newPassword"] || (input["newPassword"] && input["newPassword"].length <= 5)) {
      arr["newPassword"] = constants.CHECK_VALID_PASSWORD;
    } else {
      arr["newPassword"] = "";
    }
		if (!input["confirmPassword"] || (input["newPassword"] !== input["confirmPassword"])) {
      arr["confirmPassword"] = constants.ENTER_SAME_PASSWORD;
    } else {
      arr["confirmPassword"] = "";
    }
    setError(arr);
	}, [input])

	const handleUpdateProfile = () => {
		console.log(error.email, error.firstName, error.lastName ,!input.email , !input.firstName, !input.lastName)
		setTryUpdate(true);
		if (error.email || error.firstName || error.lastName || !input.email || !input.firstName || !input.lastName || 
				(input.currentPassword && error.currentPassword) || (input.newPassword && error.newPassword) || (input.confirmPassword && error.confirmPassword)) {
      addToast(<label>{constants.CHECK_ALL_FIELDS}</label>, { appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true })
    } else {
      setProgressStatus(true);
			const formData = new FormData();
			formData.append("firstName", input.firstName);
			formData.append("lastName", input.lastName);
			formData.append("uploadFile", input.uploadFile);
			formData.append("city", input.city);
			formData.append("postCode", input.postCode);
			formData.append("phoneNumber", input.phoneNumber);
			formData.append("businessPhone", input.businessPhone);
			formData.append("accountType", input.accountType);
			formData.append("companyName", input.companyName);
			formData.append("companyWebsite", input.companyWebsite);
			formData.append("taxNumber", input.taxNumber);
			formData.append("companyEmailAddress", input.companyEmailAddress);
			formData.append("email", input.email);
			formData.append("currentPassword", input.currentPassword);
			formData.append("newPassword", input.newPassword);
			formData.append("confirmPassword", input.confirmPassword);
			formData.append("country", selectedCountry);
			formData.append("language", selectedLanguage);
			formData.append("province", selectedProvince);
			formData.append("position", selectedPosition);
      profile
        .updateProfile(formData)
        .then(response => {
          if (response.code === 200) {
            setProgressStatus(false);
            addToast(<label>{response.message}</label>, { appearance: 'success', autoDismissTimeout: 1000, autoDismiss: true })
          } else {
            setProgressStatus(false);
            addToast(<label>{response.message}</label>, { appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true })
          }
        })
    }
	}

	const handleDeleteAccount= () => {

	}
	
	const handleUpload = (e) => {
		let newArr = {...input};
		const newImage = e.target.files[0];
		if (newImage) {
			newArr.fileName = newImage.name;
			newArr.avatarUrl = URL.createObjectURL(newImage);
			newArr.uploadFile = newImage;
		}
		setInput(newArr);
	}

  return (
    <div className={classes.root}>
      <Grid container>
				<Grid item lg={7} sm={12} xl={7} xs={12}>
					<Account
						countryList={countryList}
						languageList={languageList}
						provinceList={provinceList}
						positionList={positionList}
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
						selectedLanguage={selectedLanguage}
						setSelectedLanguage={setSelectedLanguage}
						selectedProvince={selectedProvince}
						setSelectedProvince={setSelectedProvince}
						selectedPosition={selectedPosition}
						setSelectedPosition={setSelectedPosition}
						input={input}
						setInput={setInput}
						error={error}
						setError={setError}
						tryUpdate={tryUpdate}
						setTryUpdate={setTryUpdate}
						handleUpdateProfile={handleUpdateProfile}
						handleDeleteAccount={handleDeleteAccount}
						progressStatus={progressStatus}
						handleUpload={handleUpload}
					/>
				</Grid>
				<Grid item lg={5} sm={12} xl={5} xs={12}>
					<Packages basicList={basicList} standardList={standardList} premiumList={premiumList} />
				</Grid>
			</Grid>
    </div>
  );
};

export default Profile;
