import React, { useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  IconButton,
} from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import { SingleSelect } from 'components';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { pl } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

const SortTable = (props) => {
  const classes = useStyles();
  const { history } = props;
  const {
		sortBy, sortOrder, requestSort, rows,
		searchId, setSearchId,
		invocieNumber, setSearchInvoiceNumber,
		searchType, setSearchType, typeList,
		searchDateSales, setSearchDateSales,
		searchExposure, setSearchExposure,
		searchTerm, setSearchTerm,
		searchPaid, setSearchPaid, paidList,
		searchNet, setSearchNet,
		searchGross, setSearchGross,
	} = props;
  useEffect(() => {
  }, []);

	const getZeroValue = (value) => {
		if (value < 10) 
			return '0' + value;
		return value;
	}

  const getDateTime = (value) => {
    let _date = new Date(value);
		
    return _date.getFullYear() + '.' + getZeroValue(_date.getMonth() + 1) + '.' + getZeroValue(_date.getDate()) + ' ' + getZeroValue(_date.getHours()) + ':' + getZeroValue(_date.getMinutes());
  }

  const downloadInvoice = (id) => {
		// history.push(`/ipr_list/create`);
  }

  const showInvoice = (id) => {
		// history.push(`/ipr_list`);
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy === 0}
                direction={sortOrder}
                onClick={() => requestSort(0)}
              >
								<label htmlFor="id">
									ID
								</label>
            </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 1}
                direction={sortOrder}
                onClick={() => requestSort(1)}
              >
								<label htmlFor="invoiceNumber">
									Nr faktury
								</label>
            </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 2}
                direction={sortOrder}
                onClick={() => requestSort(2)}
              >
								<label htmlFor="type">
									Typ
								</label>
            </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 3}
                direction={sortOrder}
                onClick={() => requestSort(3)}
              >
                Data sprzedaży
            </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 4}
                direction={sortOrder}
                onClick={() => requestSort(4)}
              >
                Wystawienie
            </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy === 5}
                direction={sortOrder}
                onClick={() => requestSort(5)}
              >
								<label htmlFor="term">
									Termin
								</label>
            </TableSortLabel>
            </TableCell>
						<TableCell>
              <TableSortLabel
                active={sortBy === 6}
                direction={sortOrder}
                onClick={() => requestSort(6)}
              >
								<label htmlFor="term">
									Opłacona 
								</label>
            </TableSortLabel>
            </TableCell>
						<TableCell>
              <TableSortLabel
                active={sortBy === 7}
                direction={sortOrder}
                onClick={() => requestSort(7)}
              >
								<label htmlFor="term">
									Netto
								</label>
            </TableSortLabel>
            </TableCell>
						<TableCell>
              <TableSortLabel
                active={sortBy === 8}
                direction={sortOrder}
                onClick={() => requestSort(8)}
              >
								<label htmlFor="term">
									Netto
								</label>
            </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel align="right">
                Akcje
            </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell width={100}><input className={classes.input_box} type="id" value={searchId} id="id" name="searchId" onChange={(e) => setSearchId(e.target.value)} /></TableCell>
            <TableCell><input className={classes.input_box} type="invocieNumber" value={invocieNumber} id="invocieNumber" name="invocieNumber" onChange={(e) => setSearchInvoiceNumber(e.target.value)} /></TableCell>
						<TableCell width={140}><SingleSelect value={searchType} handleChange={setSearchType} list={typeList} /></TableCell>
						<TableCell>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd.MM.yyyy"
								margin="normal"
								id="dateSales"
								value={searchDateSales}
								onChange={(e) => setSearchDateSales(e)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className={classes.date_picker}
							/>
            </TableCell>
						<TableCell>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd.MM.yyyy"
								margin="normal"
								id="dateExposure"
								value={searchExposure}
								onChange={(e) => setSearchExposure(e)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className={classes.date_picker}
							/>
            </TableCell>
						<TableCell>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd.MM.yyyy"
								margin="normal"
								id="dateTerm"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className={classes.date_picker}
							/>
            </TableCell>
						<TableCell width={140}><SingleSelect value={searchPaid} handleChange={setSearchPaid} list={paidList} /></TableCell>
            <TableCell><input className={classes.input_box} type="name" value={searchNet} id="searchNet" name="searchNet" onChange={(e) => setSearchNet(e.target.value)} /></TableCell>
						<TableCell><input className={classes.input_box} type="name" value={searchGross} id="searchGross" name="searchGross" onChange={(e) => setSearchGross(e.target.value)} /></TableCell>
            <TableCell width={130}></TableCell>
          </TableRow>
          {rows.map((item, indx) => {
            return (
              <TableRow key={indx} className={classes.root}>
                <TableCell>{item.id_invoice}</TableCell>
                <TableCell>{item.invocieNumber}</TableCell>
                <TableCell>{typeList && typeList.length > 0 && typeList[item.type - 1].name}</TableCell>
								<TableCell>{getDateTime(item.dateSales)}</TableCell>
								<TableCell>{getDateTime(item.exposure)}</TableCell>
                <TableCell>{getDateTime(item.term)}</TableCell>
								<TableCell>{paidList && paidList.length > 0 && paidList[item.paid - 1].name}</TableCell>
								<TableCell>{item.net}</TableCell>
								<TableCell>{item.gross}</TableCell>
                <TableCell>
                  <IconButton variant="outlined" aria-label='Pokaż listę IPR' component="span" className={classes.iconButton} onClick={() => showInvoice(item.id_invoice)}>
                    <FindInPageOutlinedIcon className={classes.icon} />
                  </IconButton>
                  <IconButton variant="outlined" aria-label='Dodaj listę IPR' component="span" className={classes.iconButton} onClick={() => downloadInvoice(item.id_invoice)}>
                    <SystemUpdateAltIcon className={classes.icon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </MuiPickersUtilsProvider>
  );
};

export default withRouter(SortTable);
