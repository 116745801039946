import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.white,
		height: 'calc(100vh - 80px)',
		overflow: 'auto',
	},
	number: {
		fontSize: 13,
		fontWeight: 'bold',
		padding: '0px 4px',
	},
	title: {
		fontSize: 12,
	},
	titleContainer: {
		paddingTop: 40,
		display: 'flex',
		'& hr': {
			margin: theme.spacing(0, 3),
			backgroundColor: theme.palette.text.primary,
		},
	},
	externalIcon: {
		width: 30,
		height: 30,
		marginRight: 16,
	},
	externalContainer: {
		display: 'flex',
    alignItems: 'center',
    background: theme.palette.sidebar_background_color,
    borderRadius: 3,
    padding: '18px 15px',
    marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		width: '100%',
	},
	largeAvatar: {
		width: theme.spacing(12),
    height: theme.spacing(12),
	}
}));

export default useStyles;
