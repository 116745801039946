import React from 'react';
import useStyles from './style';
import { Typography, IconButton } from '@material-ui/core';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const HistoryProjectItem = props => {
	const classes = useStyles();
	const { item } = props

	return (
		<div className={classes.root}>
			<div className={classes.backgroundImage} style={{ backgroundImage: `url(${item.image})` }} />
			<IconButton aria-label="save" className={classes.btnSave}>
				<SaveOutlinedIcon className={classes.iconSize} fontSize="small" />
			</IconButton>
			<IconButton aria-label="change" className={classes.btnChange}>
				<EditOutlinedIcon className={classes.iconSize} fontSize="small" />
			</IconButton>
			<div className={classes.descriptionContainer}>
				<Typography variant="body2">{item.placeName}</Typography>
				<div className={classes.cameraContainer}>
					<Typography variant="caption">{item.date}</Typography>
					<div className={classes.camera}>
						<VideocamOutlinedIcon fontSize="small" />
						<Typography variant="caption">{item.counter}</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HistoryProjectItem;
