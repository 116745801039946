import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {    
    padding: theme.spacing(0),
  },
	paper: {
		height: '55vh',
		position: 'relative'
	},
	paperContainer: {
		backgroundColor: theme.palette.topbar_background,
		borderRadius: '3px 3px 0px 0px',
	},
	subscriptionAvatar: {
		padding: theme.spacing(3),
		'& path': {
			fill: theme.palette.white,
		},
	},
	subscriptionType: {
		color: '#E5F3FD',
		fontSize: '24px'
	},
	subscriptionPrice: {
		fontSize: '28px',
		color: theme.palette.white,
		marginTop: '8px'
	},
	subscriptionPlan: {
		fontSize: '12px',
		color: theme.palette.white,
		paddingBottom: '10px'
	},
	checkContainer: {
		textAlign: 'left',
		marginTop: '12px',
		paddingLeft: '12px',
		'& .MuiFormControlLabel-root':{
			height: '26px',
			fontSize: '14px',
		},
	},
	checkOut: {
		color: theme.palette.topbar_background,
		fontSize: '16px',
		position: 'absolute',
    bottom: '16px',
		width: '100%',
	},
}));

export default useStyles;
