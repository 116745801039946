import React, { useEffect } from 'react';
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	TableSortLabel,
	IconButton,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { withRouter } from 'react-router-dom';
import {
	KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import useStyles from './style';
import DateFnsUtils from '@date-io/date-fns';
import { pl } from 'date-fns/locale'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const SortTable = (props) => {
	const classes = useStyles();
	const { history } = props;
	const {
		sortBy, sortOrder, requestSort, rows, selectedCount,
		searchTopic, setSearchTopic,
		searchDate, setSearchDate,
		handleDelete,
	} = props;
	useEffect(() => {
	}, []);

	const getZeroValue = (value) => {
		if (value < 10)
			return '0' + value;
		return value;
	}

	const getDateTime = (value) => {
		let _date = new Date(value);

		return _date.getFullYear() + '.' + getZeroValue(_date.getMonth() + 1) + '.' + getZeroValue(_date.getDate()) + ' ' + getZeroValue(_date.getHours()) + ':' + getZeroValue(_date.getMinutes());
	}

	const handleClickViewHisotry = () => {

	}

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<TableSortLabel
								active={sortBy === 0}
								direction={sortOrder}
								onClick={() => requestSort(1)}
							>
								<label htmlFor="Topic">
									Temat
							</label>
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={sortBy === 1}
								direction={sortOrder}
								onClick={() => requestSort(1)}
							>
								<label htmlFor="date">
									Data
							</label>
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel>
								Akcje
            </TableSortLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell><input className={classes.input_box} type="name" value={searchTopic} name="searchTopic" id="searchTopic" onChange={(e) => setSearchTopic(e.target.value)} /></TableCell>
						<TableCell>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd.MM.yyyy"
								margin="normal"
								id="date"
								value={searchDate}
								onChange={(e) => setSearchDate(e)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className={classes.date_picker}
							/>
						</TableCell>
						<TableCell width={115}></TableCell>
					</TableRow>
					{rows.map((item, indx) => {
						return (
							<TableRow key={indx} className={classes.root}>
								<TableCell>{item.topic}</TableCell>
								<TableCell>{getDateTime(item.updated_at)}</TableCell>
								<TableCell>
									<IconButton aria-label="view topic hisotry" component="span" className={classes.iconButton} onClick={handleClickViewHisotry}>
										<VisibilityOutlinedIcon className={classes.icon} />
									</IconButton>
									<IconButton variant="outlined" aria-label="delet topic history" component="span" className={classes.iconButton} onClick={() => handleDelete(item.id)}>
										<DeleteOutlineOutlinedIcon className={classes.icon} />
									</IconButton>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</MuiPickersUtilsProvider>
	);
};

export default withRouter(SortTable);
