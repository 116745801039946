import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(0),
		borderRadius: 4,
		position: 'relative',
	},
	backgroundImage: {
		height: 150,
    borderRadius: 4,
		marginBottom: 10,
	},
	readOn: {
		marginTop: 10,
		textAlign: 'right',
	},
	description: {
		fontSize: 12,
	},
	title: {
		fontSize: 12,
		fontWeight: 'bold',
	},
}));

export default useStyles;
