import React from 'react';
import PropTypes from 'prop-types';
import { SiteInfoContextConsumer } from "App";
import { useTheme } from '@material-ui/styles';
import useStyles from './style';
import { Grid, IconButton, Button } from '@material-ui/core';
import { Topbar } from './components';
import DeleteIcon from '@material-ui/icons/Delete';

const MapLayout = props => {
	const { children } = props;
	const theme = useTheme();
	const classes = useStyles(theme);

	return (
		<SiteInfoContextConsumer>
			{ (props) => (
				<div className={classes.root}>
					<Topbar />
					<div className={classes.main}>
						<main className={classes.content}>{children}</main>
					</div>
				</div>
			)}
		</SiteInfoContextConsumer>
	);
};

MapLayout.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

export default MapLayout;
