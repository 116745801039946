import React from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import useStyles from './style';

const Contact = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
				<Grid container item xs={12} justify="flex-start">
					<Typography variant="h4" gutterBottom>Kontackt</Typography>
				</Grid>
				<Grid item xs={2}></Grid>
				<Grid container item xs={6} spacing={3}>
					<Grid item xs={12}>
						<Typography variant="subtitle2" gutterBottom>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sed dapibus leo nec ornare diam sedasd commodo nibh ante facilisis bibendum dolor feugiat at.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom>Adres e-mail</Typography >
						<TextField 
							id="email" 							
							placeholder="mail@mail.pl"
							variant="outlined" 
							className={classes.fullwidth}
						/>
					</Grid>						
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom>Opis</Typography>
						<TextField
							id="description"
							multiline
							rows={5}
							placeholder="Prosimy o zadawanie pytań."
							variant="outlined"
							className={classes.fullwidth}
						/>
					</Grid>
					<Grid container item xs={12} justify="space-between" >
						<Grid item xs={3}>
							<Button variant="contained" color="secondary" className={classes.btnSend}>
								Wróć
							</Button>
						</Grid>
						<Grid item xs={3}>
							<Button variant="contained" color="secondary" className={classes.btnSend}>
								Wysłać
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}></Grid>
			</Grid>
    </div>
  );
};

export default Contact;
