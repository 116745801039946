import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {    
    padding: theme.spacing(2),
		color: theme.palette.text.primary
  },
	packageContainer: {
		textAlign: 'center',
		marginTop: theme.spacing(3),
	},
	subtitle1: {
		color: theme.palette.topbar_background,
		fontSize: '0.935em',
	},
	subtitle2: {
		color: theme.palette.topbar_background,
		fontSize: '0.935em',
		fontWeight: '500',
	},
	subtitle3: {
		marginTop: theme.spacing(3),
		color: theme.palette.topbar_background,
		fontSize: '0.935em',
	},
	subtitle4: {
		marginTop: theme.spacing(3),
		marginBottom:  theme.spacing(8),
		color: theme.palette.topbar_background,
		fontSize: '2em',
	},
	typeContainer: {
		textAlign: 'center',
	}
}));

export default useStyles;
