import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(0),
		height: '100%',
		borderRadius: 4,
	},
	backgroundImage: {
		height: 100,
    borderRadius: 4,
	},
}));

export default useStyles;
