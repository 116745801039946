import React, { useState } from 'react';
import {
	Grid,
	Typography,
	Button,
	Avatar,
	Divider,
} from '@material-ui/core';
import useStyles from './style';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';

const MeMessage = (props) => {
	const classes = useStyles();
	const { content } = props;
	return (
		<div className={classes.root}>
			<Grid container spacing={3} justify="flex-end">
				<Grid item></Grid>
				<Grid item xs={8} className={classes.alignRight}>
					<div className={classes.messageContent}>
						{content}
					</div>
				</Grid>
				<Grid item>
					<Avatar className={classes.avatar}>
						<EmojiEmotionsOutlinedIcon />
					</Avatar>				
				</Grid>
			</Grid>
		</div>
	);
};

export default MeMessage;
