import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Subscription } from './components'
import useStyles from './style';

const Packages = (props) => {
	const classes = useStyles();
	const {	basicList, standardList, premiumList } = props;

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="subtitle1" gutterBottom>Pakiety</Typography>
				</Grid>
				<Grid container item xs={12} className={classes.packageContainer}>
					<Grid item xs={12}>
						<span className={classes.subtitle1}>Aktualnie korzystasz z</span>
						<span className={classes.subtitle2}> bezpłatnej wersji testowe</span>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.subtitle3}>Z obecnej wersji możesz korzystać jeszcze przez</div>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.subtitle4}>7 dni</div>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body1" gutterBottom>Zapoznaj się z naszymi pakietami</Typography>
				</Grid>
				<Grid container item xs={12} className={classes.typeContainer} spacing={2}>
					<Grid item xs={4}><Subscription data={basicList}/></Grid>
					<Grid item xs={4}><Subscription data={standardList}/></Grid>
					<Grid item xs={4}><Subscription data={premiumList}/></Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Packages;
