import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(0),
		backgroundColor: theme.palette.sidebar_background_color,
		borderRadius: 3,
		position: 'relative',
	},
	backgroundImage: {
		height: 195,
    borderRadius: '4px 4px 0px 0px',
	},
	descriptionContainer: {
		padding: theme.spacing(1),
	},
	cameraContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	camera: {
		display: 'flex',
		alignItems: 'center',
	},
	iconSize: {
		fontSize: 16,
	},
	btnSave: {
		backgroundColor: theme.palette.topbar_background,
    color: 'white',
    padding: 5,
		position: 'absolute',
    top: 5,
    right: 5,
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
	btnChange: {
		backgroundColor: theme.palette.topbar_background,
    color: 'white',
    padding: 5,
		position: 'absolute',
		top: 180,
		right: 5,
    '&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
}));

export default useStyles;
