import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Button, Divider, Paper, Typography } from '@material-ui/core';
import { SingleSelect } from 'components';
import invoice from '../../apis/invoice';
import EXCEL from 'js-export-xlsx';
import { useToasts } from 'react-toast-notifications'
import Pagination from '@material-ui/lab/Pagination';
import { SortTable, DeleteModal } from './components';

const countList = [
	{ id: 1, name: 10 },
	{ id: 2, name: 25 },
	{ id: 3, name: 50 },
]
const tempTypeList = [
	{ id: 1, name: 'type1' },
	{ id: 2, name: 'type2' },
	{ id: 3, name: 'type3' },
]
const tempPaidList = [
	{ id: 1, name: 'paid1' },
	{ id: 2, name: 'paid2' },
	{ id: 3, name: 'paid3' },
]
const tempInvoice = [
	{ id: 1, id_invoice: 1, invocieNumber: 123456, type: 1, dateSales: '01.01.2021', exposure: '01.01.2021', term: '01.01.2021', paid: 1, net: 'net1', gross: 'gross1' },
	{ id: 2, id_invoice: 2, invocieNumber: 1234567, type: 2, dateSales: '01.02.2021', exposure: '01.02.2021', term: '01.02.2021', paid: 2, net: 'net2',gross: 'gross2' },
	{ id: 3, id_invoice: 3, invocieNumber: 1234568, type: 3, dateSales: '01.03.2021', exposure: '01.03.2021', term: '01.03.2021', paid: 3, net: 'net3',gross: 'gross3' },
	{ id: 4, id_invoice: 4, invocieNumber: 123459, type: 2, dateSales: '01.04.2021', exposure: '01.04.2021', term: '01.04.2021', paid: 2, net: 'net4',gross: 'gross4' },
	{ id: 5, id_invoice: 5, invocieNumber: 1234, type: 1, dataSeles: '01.05.2021', exposure: '01.05.2021', term: '01.05.2021', paid: 1, net: 'net5', gross: 'gross5' },
]

const Payment = (props) => {
	const classes = useStyles();
	const { history } = props;
	// const [countList, setCountList] = useState([25, 50, 100]);
	const [selectedCount, setSelectedCount] = useState(25);
	const [sortOption, setSortOption] = useState({ sortBy: 0, sortOrder: "asc" });
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [searchId, setSearchId] = useState('');
	const [invocieNumber, setSearchInvoiceNumber] = useState('')
	const [searchType, setSearchType] = useState('');
	const [typeList, setTypeList] = useState([]);
	const [searchDateSales, setSearchDateSales] = useState(new Date('2020-01-01'))
	const [searchExposure, setSearchExposure] = useState(new Date('2020-01-01'))
	const [searchTerm, setSearchTerm] = useState(new Date('2020-01-01'))
	const [searchPaid, setSearchPaid] = useState('');
	const [paidList, setPaidList] = useState([])
	const [searchNet, setSearchNet] = useState('');
	const [searchGross, setSearchGross] = useState('');
	const [progressStatus, setProgressStatus] = useState(false);
	const { addToast } = useToasts();

	useEffect(() => {
		invoice.getInfo()
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else {
					// if (response.code === 200) {
						// setTypeList(response.data.type);
						// setPaidList(response.data.paid);
						setTypeList(tempTypeList);
						setPaidList(tempPaidList);
					// }
				}
			})
		handleSearch();
	}, []);

	useEffect(() => {
		handleSearch();
	}, [sortOption, page]);

	useEffect(() => {
		handleSearch();
		setPage(1);
	}, [selectedCount, searchId, invocieNumber, searchType, searchDateSales, searchExposure, searchTerm, searchPaid, searchNet, searchGross]);

	const requestSort = (pSortBy) => {
		var sortOrder = "asc";
		if (pSortBy === sortOption.sortBy) {
			sortOrder = (sortOption.sortOrder === "asc" ? "desc" : "asc");
		}
		setSortOption({ sortBy: pSortBy, sortOrder: sortOrder })
	}

	const handleSearch = () => {
		invoice
			.getListByOption(sortOption.sortBy, sortOption.sortOrder, selectedCount, page, searchId, invocieNumber, searchType, searchDateSales, searchExposure, searchTerm, searchPaid, searchNet, searchGross)
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else {
					// if (response.code === 200) {
						// setData(response.data.invoice);
						// setTotal(response.data.count);
						setData(tempInvoice);
						setTotal(5);
					// }
				}
			})
	}

	const handleExport = () => {
		let export_data = [];
		for (let i = 0; i < data.length; i++) {
			let item = [];
			item.push(data[i].id);
			item.push(data[i].invocieNumber, );
			item.push(typeList[data[i].type - 1].name);
			let date = new Date(data[i].dateSales);
			item.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
			date = new Date(data[i].exposure);
			item.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
			date = new Date(data[i].term);
			item.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
			item.push(paidList[data[i].paid - 1].name);
			item.push(data[i].net);
			item.push(data[i].gross);
			export_data.push(item);
		}
		EXCEL.outPut({
			header: ['ID', 'Nr faktury', 'Typ', 'Data sprzedaży', 'Wystawienie', 'Termin ', 'Opłacona ', 'Netto', 'Brutto'],
			data: export_data,
			name: 'download'
		})
	}

	return (
		<div className={classes.root}>
			<div className={classes.titleContainer}>
				<Typography variant="h6">Twoje konto / Płatności i faktury</Typography>
				<div>
					<Button variant="outlined" className={classes.btnExport} onClick={handleExport}>Eksport listy do XLS</Button>
					<Button variant="outlined" className={classes.btnExport}>Eksport listy do CSV</Button>
				</div>
			</div>
			<Divider className={classes.divider}></Divider>
			<div className={classes.pageNumberContainer}>
				<Typography variant="body1">Pokaz:</Typography>
				<SingleSelect value={selectedCount} handleChange={setSelectedCount} list={countList} />
				<Typography variant="body1">pozycji</Typography>
			</div>
			<Paper elevation={3} className={classes.table}>
				<SortTable
					rows={data}
					requestSort={requestSort}
					sortOrder={sortOption.sortOrder}
					sortBy={sortOption.sortBy}
					selectedCount={selectedCount}
					searchId={searchId}
					setSearchId={setSearchId}
					invocieNumber={invocieNumber}
					setSearchInvoiceNumber={setSearchInvoiceNumber}
					searchType={searchType}
					setSearchType={setSearchType}
					typeList={typeList}
					searchDateSales={searchDateSales}
					setSearchDateSales={setSearchDateSales}
					searchExposure={searchExposure}
					setSearchExposure={setSearchExposure}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					searchPaid={searchPaid}
					setSearchPaid={setSearchPaid}
					paidList={paidList}
					searchNet={searchNet}
					setSearchNet={setSearchNet}
					searchGross={searchGross}
					setSearchGross={setSearchGross}
				/>
				<div className={classes.pagination}>
					<Pagination
						className={classes.pagenation_class}
						count={total % selectedCount == 0 ? total / selectedCount : parseInt(total / selectedCount) + 1}
						onChange={(e, page) => { setPage(page) }}
						page={page}
						showFirstButton
						showLastButton />
				</div>
			</Paper>
		</div>
	);
};

export default Payment;
