import React from 'react';
import useStyles from './style';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Typography, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const NewProjectItem = props => {
	const classes = useStyles();
	const  history = useHistory();
	const { title } = props;
	
	const handleAddProject = () => {
		history.push('/project');
	}

	return (
		<div className={classes.root}>
			<div className={classes.addContainer}>
				<IconButton onClick={handleAddProject}>
					<AddCircleIcon className={classes.addProject} />
				</IconButton>
				<Typography variant="body2">{title}</Typography>
			</div>

		</div>
	);
}

export default NewProjectItem;
