import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		color: theme.palette.text.primary
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	btnExport: {
		color: theme.palette.pink,
		fontSize: 13,
		padding: '5px 25px',
		marginBottom: 10,
		marginLeft: 32,
		textTransform: 'none',
		borderColor: theme.palette.pink,
		fontWeight: 400,
	},
	divider: {
		backgroundColor: theme.palette.text.primary,
		margin: '16px 0px',
	},
	pageNumberContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'& .MuiFormControl-root': {
			width: '100px',
			margin: '0px 10px'
		},
		marginBottom: 16,
	},
	table: {
		margin: theme.spacing(2.5, 0),
		border: theme.palette.card_border,
	},
	pagination: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(1)
	},
	pagenation_class: {
		'& .MuiPaginationItem-page.Mui-selected': {
			backgroundColor: theme.palette.pagination_background,
			color: theme.palette.pagination_color
		}
	}
}));

export default useStyles;
