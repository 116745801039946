import axios from 'axios';
import authHeader from './auth-header';
import storage from '../utils/storage';

class Profile {
	getInfo = () => {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/profile/info`, {
				headers: authHeader(storage.getStorage('token')),
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
					return response.data;
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}

	getUserProfile = () => {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/profile`, {
				headers: authHeader(storage.getStorage('token')),
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
					return response.data;
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}

	updateProfile = (formData) => {
		return axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/profile/update`, formData, {
				headers: authHeader(storage.getStorage('token')),
				'Content-Type': 'multipart/form-data'
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}
}

export default new Profile();
