import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.white,
		height: 'calc(100vh - 80px)',
		overflow: 'auto',
	},
	messageList: {
		height: 480,
    overflow: 'auto',
    borderTop: `1px solid ${theme.palette.text.light}`,
    borderBottom: `1px solid ${theme.palette.text.light}`,
		padding: theme.spacing(2),
	},
	textArea: {
		padding: '10px 20px',
		color: theme.palette.text.primary,
		width: '100%',
		fontFamily: 'roboto',
	},
	avatar: {
    backgroundColor: theme.palette.secondary.light,
    height: 40,
    width: 40,
  },
	activeAvatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 40,
    width: 40,
  },
	input_box_label: {
		marginTop: theme.spacing(0.7),
		marginBottom: theme.spacing(0.3),
		fontSize: '0.75em',
	},
	input_box: {
		'& svg': {
			fill: theme.palette.text.secondary
		},
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.black_white,
		border: `1px solid ${theme.palette.text.primary}`,
		padding: '8px 20px',
		width: '100%',
	},
	btCommon: {
    fontSize: '12px',
    textTransform: 'unset',
    borderRadius: 'unset',
		fontWeight: 300,
		paddingTop: 6,
		paddingBottom: 6,
		width: '100%',
	},
	btSend: {
		paddingLeft: 7,
		marginLeft: '-1px',
		minWidth: 56,
	},
	divider: {
		backgroundColor: theme.palette.text.light,
		width: '100%',
		height: 1,
		margin: 'unset',
	},
}));

export default useStyles;
