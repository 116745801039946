import axios from 'axios';
import authHeader from './auth-header';
import storage from '../utils/storage';
class Invoice {
	getInfo = () => {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/invoice/info`, {
				headers: authHeader(storage.getStorage('token')),
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
					return response.data;
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}
	get = (id) => {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/invoice`, {
				headers: authHeader(storage.getStorage('token')),
				params: {
					id: id
				},
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
					return response.data;
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}

	getListByOption = (sort_column, sort_order, count, page, searchId, invocieNumber, searchType, searchDateSales, searchExposure, searchTerm, searchPaid, searchNet, searchGross) => {
		return axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/invoice/getListByOption`, {
				sort_column: sort_column,
				sort_order: sort_order,
				count: count,
				page: page,
				searchId: searchId,
				invocieNumber: invocieNumber,
				searchType: searchType,
				searchDateSales: searchDateSales,
				searchExposure: searchExposure,
				searchTerm: searchTerm,
				searchPaid: searchPaid,
				searchNet: searchNet,
				searchGross: searchGross,
			}, {
				headers: authHeader(storage.getStorage('token'))
			})
			.then(response => {
				if (response.data.code === 401)
					storage.removeStorage('token');
				return response.data;
			}).catch(error => {
				return error;
			})
	}
}
export default new Invoice();
