import React, { useState } from 'react';
import {
	Grid,
	Typography,
	Button,
	Avatar,
	Divider,
} from '@material-ui/core';
import useStyles from './style';
import LanguageIcon from '@material-ui/icons/Language';
import { NewProjectItem, RecentProjectItem } from './components'
import userAvatar from 'assets/img/avatar.jpg'
import recentImage from 'assets/img/recent_project.png';

const recentProjectList = [
	{ id: 1, placeName: 'ul.Wandy we Wroctawiu', date: '05.01.2021', counter: 12, image: recentImage},
	{ id: 2, placeName: 'Rndo Powstancow Slaskich', date: '05.03.2021', counter: 4, image: recentImage},
	{ id: 3, placeName: 'Sky Tower', date: '05.03.2021', counter: 19, image: recentImage},
	{ id: 4, placeName: 'Wroctawski Rynek', date: '05.04.2021', counter: 2, image: recentImage},
	{ id: 5, placeName: 'Business Garden', date: '05.05.2021', counter: 9, image: recentImage},
	{ id: 6, placeName: 'Business Garden', date: '05.05.2021', counter: 9, image: recentImage},
]

const RecentProject = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={2}>
					<Avatar alt="avatar" src={`${userAvatar}`} className={classes.largeAvatar} />
				</Grid>
				<Grid item xs={10}>
					<Typography variant="h5">Witaj, Jan Kowalski!</Typography>
					<Typography variant="body2" color="secondary">ustawienia profilu</Typography>
					<div className={classes.titleContainer}>
						<span className={classes.number}>10</span><span className={classes.title}>projektow</span>
						<Divider orientation="vertical" flexItem />
						<span className={classes.number}>15</span><span className={classes.title}> eksportow</span>
						<Divider orientation="vertical" flexItem />
						<span className={classes.title}>Pakiet</span><span className={classes.number}> Promium</span>
					</div>
				</Grid>
				<Grid container>
					<Grid container>
						<div className={classes.externalContainer}>
							<LanguageIcon className={classes.externalIcon}/>
							<Typography variant="body1">Zapraszamy na nasza oficjalna</Typography>
							<Typography variant="body1" color="secondary">&nbsp;&nbsp;Strone internetowa!</Typography>
						</div>
						{/* <Grid item xs={1}>
						</Grid>
						<Grid item xs={4}>
						</Grid>
						<Grid item xs={4}>
						</Grid> */}
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="body1">Ostatnie projekty</Typography>
						</Grid>
						<Grid item lg={4} sm={6} xs={12}>
							<NewProjectItem title="Stworz nowy projekt" />
						</Grid>
						{recentProjectList.map((item, index) => (
							<Grid key={index} item lg={4} sm={6} xs={12}>
								<RecentProjectItem item={item} />
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default RecentProject;
