import React, { useState, useRef, useEffect } from 'react';
import {
	Grid,
	Typography,
	Button,
	Avatar,
	Divider,
	IconButton,
} from '@material-ui/core';
import useStyles from './style';
import { SingleSelect } from 'components/';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import { ThemMessage, MeMessage } from './components'

const Account = (props) => {
	const classes = useStyles();
	const { topicList, messageList, topic, handleChangeTopic, handleSendMessage, handleCreateFeedback } = props;
	const [message, setMessage] = useState('');
	const [level, setLevel] = useState(1);
	const [opinion, setOpinion] = useState('');
	const messageBoxRef = useRef(null);

	useEffect(() => {
		messageBoxRef.current.scrollTop = 1000000;
	}, [messageList]);

	const handleChangeMessage = (event) => {
		setMessage(event.target.value);
	}
	const handleChangeOpinion = (event) => {		
		setOpinion(event.target.value);
	}
	const _sendMessage = () => {
    if (message.length > 0) {
			handleSendMessage(1, topic, message)
    }
		setMessage('');
		console.log(messageList)
  }	
	const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      _sendMessage();
    }
  }
	const handleChangeLevel = (level) => {
		setLevel(level);
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="subtitle1">Pomoc / Chat z konsultantem</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider className={classes.divider} variant="fullWidth" />
				</Grid>
				<Grid item xs={3}>
					<div className={classes.input_box_label}><label htmlFor="topic">Wybierz temat rozmowy:</label></div>
				</Grid>
				<Grid item xs={9}>
					<SingleSelect value={topic} handleChange={handleChangeTopic} list={topicList} />	
				</Grid>
				<Grid item xs={12}>
					<div className={classes.messageList} ref={messageBoxRef}>
						{ 
							messageList.map((item, index) => (
								item.to === 1 ? <MeMessage content={item.content} key={index} /> : <ThemMessage content={item.content} key={index} />
							))						
						}
					</div>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={11}>
							<input className={classes.input_box} type="text" value={message} name="message" id="message" onChange={handleChangeMessage} onKeyPress={handleKeyPress} autoComplete='off' />
						</Grid>
						<Grid item xs={1}>
							<Button
								variant="contained"
								color="secondary"
								endIcon={<SendOutlinedIcon />}
								className={classes.btSend}
								onClick={_sendMessage}
							/>
						</Grid>
					</Grid>
				</Grid>				
				<Grid item xs={4}>
					<Typography variant="body1" color="secondary" gutterBottom>Ocen chat</Typography>
					<Grid container spacing={1} justify="space-around">
						<Grid item xs={4}>
							<IconButton aria-label="delete" className={classes.margin} onClick={() => handleChangeLevel(1)}>								
								<Avatar className={level === 1? classes.activeAvatar : classes.avatar}>
									<EmojiEmotionsOutlinedIcon fontSize="large" />
								</Avatar>
							</IconButton>
						</Grid>
						<Grid item xs={4}>
							<IconButton aria-label="delete" className={classes.margin} onClick={() => handleChangeLevel(2)}>								
								<Avatar className={level === 2? classes.activeAvatar : classes.avatar}>
									<EmojiEmotionsOutlinedIcon fontSize="large" />
								</Avatar>
							</IconButton>
						</Grid>
						<Grid item xs={4}>
							<IconButton aria-label="delete" className={classes.margin} onClick={() => handleChangeLevel(3)}>								
								<Avatar className={level === 3? classes.activeAvatar : classes.avatar}>
									<EmojiEmotionsOutlinedIcon fontSize="large" />
								</Avatar>
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={8}>
					<Typography variant="caption">Napisz swoją opinię</Typography>
					<textarea className={classes.textArea} rows="4" type="text" value={opinion} name="opinion" id="opinion" onChange={handleChangeOpinion} autoComplete='off' />
				</Grid>
				<Grid item xs={12}>
					<Grid container justify="flex-end">
						<Grid item>
							<Button variant="contained" color="secondary" className={classes.btCommon} onClick={() => handleCreateFeedback(topic, level, opinion)}>
								Zakoncz chat
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default Account;
