import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.sidebar_background_color,		
		alignItems: 'center',
		height: '100%',
		borderRadius: 3,
		display: 'flex',
		justifyContent: 'center',
	},
	addContainer: {
		textAlign: 'center',
	},
	addProject: {
		width: 60,
		height: 60,
		color: theme.palette.topbar_background,
	},
}));

export default useStyles;
