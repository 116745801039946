import React, { useState } from "react";
import {useRef, useEffect} from 'react';

import L from "leaflet";
import { Map, TileLayer, FeatureGroup, Circle, SVGOverlay} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import useStyles from './style';
import Button from '@material-ui/core/Button';
import { getGeoJson } from './data';

import { Camera } from 'components/';
import { set } from "date-fns";


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	iconUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	shadowUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});


const Project = () => {
	
	const classes = useStyles();
	const [center, setCenter] = useState({ lat: 37.830124319877235, lng: -122.47979164123535 });
	const [cameraBounds, setBounds] = useState([
		[center.lat + 0.000009009 * 50, center.lng + 0.000009009 *50],
		[center.lat - 0.000009009 * 50, center.lng - 0.000009009 *50],
	  ]);
	const [zoomLevel, setZoomLevel] = useState(20);
	// const  metersPerPx =  (156543.03392 * Math.cos( 0.000009009 * Math.PI / 180) / Math.pow(2, zoomLevel)); // meters per pixel with zoom-level
	const  [metersPerPx, setMeterPix] =  useState(156543.03392 * Math.cos( 0.000009009 * Math.PI / 180) / Math.pow(2, 20));

	const [cameraR, setCameraR] = useState(50 / metersPerPx); // 50m camera
	const [angleDirection, setAngleDirection] = useState(60); // direction of camera
	const [angleSize, setAngleSize] = useState(60); // angle of camera
	const [fillColor, setFillColor] = useState("rgba(0,255,0,0.25)"); // fill color
	const [strokeColor, setStrokeColor] = useState("rgba(0,255,0,1)"); // stocke color	

	const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
	const googleSat = 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
	const googleSubdomains = ['mt0', 'mt1', 'mt2', 'mt3']

	const mapRef = useRef();	

	const handleZoomStart = () => {
		setZoomLevel(mapRef.current.leafletElement.getZoom());
		//setMeterPix(156543.03392 * Math.cos( 0.000009009 * Math.PI / 180) / Math.pow(2, zoomLevel));
		//setCameraR(50/metersPerPx);
		console.log("++++++++++++++++", zoomLevel);
		console.log("++++++++++++++++", cameraR);
	};

	useEffect(() => {
	  }, []);

	const _onEdited = (e) => {
		let numEdited = 0;
		e.layers.eachLayer((layer) => {
			numEdited += 1;
		});
		console.log(`_onEdited: edited ${numEdited} layers`, e);

		_onChange();
	};

	const _onCreated = (e) => {
		let type = e.layerType;
		let layer = e.layer;
		console.log('_onCreated: ', type, e);
		// Do whatever else you need to. (save to db; etc)

		_onChange();
	};

	const _onDeleted = (e) => {
		let numDeleted = 0;
		e.layers.eachLayer((layer) => {
			numDeleted += 1;
		});
		console.log(`onDeleted: removed ${numDeleted} layers`, e);

		_onChange();
	};

	const _onMounted = (drawControl) => {
		console.log('_onMounted', drawControl);
	};

	const _onEditStart = (e) => {
		console.log('_onEditStart', e);
	};

	const _onEditStop = (e) => {
		console.log('_onEditStop', e);
	};

	const _onDeleteStart = (e) => {
		console.log('_onDeleteStart', e);
	};

	const _onDeleteStop = (e) => {
		console.log('_onDeleteStop', e);
	};

	let _editableFG = null;

	const _onFeatureGroupReady = (reactFGref) => {
		// populate the leaflet FeatureGroup with the geoJson layers
		if (reactFGref) {
			// console.log('++++1', reactFGref)
			let leafletGeoJSON = new L.GeoJSON(getGeoJson());
			// console.log('++++2', leafletGeoJSON)
			let leafletFG = reactFGref.leafletElement;
			leafletGeoJSON.eachLayer((layer) => {
				leafletFG.addLayer(layer);
			});
			// store the ref for future access to content
			_editableFG = reactFGref.leafletElement;
			// console.log('+++++++++++++2', zoomLevel)
		}
	};

	const _onChange = () => {
		// this._editableFG contains the edited geometry, which can be manipulated through the leaflet API
		if (!_editableFG) {
			return;
		}
		const geojsonData = _editableFG.toGeoJSON();
		console.log('geojson changed', geojsonData);
	};
	  
	return (
		
		<div className={classes.root}>
			<Map center={center} zoom={zoomLevel} ref={mapRef} zoomControl={true} onZoomStart={handleZoomStart}>
				<FeatureGroup
					ref={(reactFGref) => {
						_onFeatureGroupReady(reactFGref);
					}}
				>
					<EditControl
						position="topleft"
						onCreated={_onCreated}
						onEdited={_onEdited}
						onDeleted={_onDeleted}
						onMounted={_onMounted}
						onEditStart={_onEditStart}
						onEditStop={_onEditStop}
						onDeleteStart={_onDeleteStart}
						onDeleteStop={_onDeleteStop}
						draw={
							{
								/* rectangle: false,
							circle: false,
							circlemarker: false,
							marker: false,
							polyline: false, */
							}
						}
					/>
				</FeatureGroup>
				<Circle center={center} radius={1000} />				
				<SVGOverlay attributes={{ stroke: 'red' }} bounds={cameraBounds}>
					{/* <rect x="0" y="0" width="100%" height="100%" fill="white"/> */}
					<Camera x={cameraR} y={cameraR} angle={angleDirection} fov={angleSize} range={cameraR-10}
					fillColor={fillColor} strokeColor={strokeColor}/>
				</SVGOverlay>
				
				<TileLayer
					url={googleSat}
					subdomains={googleSubdomains}
					maxZoom='22'
				/>				
			</Map>		

			<Button variant="contained" color="primary">button</Button>
		</div>
	);

};



export default Project;
