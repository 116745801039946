import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.white,
		height: 'calc(100vh - 80px)',
		overflow: 'auto',
	},
	input_box_label: {
		marginTop: theme.spacing(0.7),
		marginBottom: theme.spacing(0.3),
		fontSize: '0.75em',
	},
	input_box: {
		'& svg': {
			fill: theme.palette.text.secondary
		},
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.black_white,
		border: `1px solid ${theme.palette.text.primary}`,
		padding: '8px 20px',
		width: '100%',
	},
	input_upload: {
    display: 'none',
  },
	btCommon: {
    fontSize: '12px',
    textTransform: 'unset',
    borderRadius: 'unset',
		fontWeight: 300,
		paddingTop: 6,
		paddingBottom: 6,
		width: '100%',
	},
	logoAvatar: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
	require: {
		color: 'red',
	},
	divider: {
		backgroundColor: theme.palette.text.secondary,
		color: 'red',
		width: '100%',
		height: 1,
		margin: 'unset',
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5)
	},
	error_log: {
    color: 'red',
    fontFamily: 'roboto',
		fontSize: 11,
  },
	progressContainer: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    color: theme.palette.pink
  }
}));

export default useStyles;
