import React from 'react';
import useStyles from './style';

const InstagramItem = props => {
	const classes = useStyles();
	const { item } = props

	return (
		<div className={classes.root}>
			<div className={classes.backgroundImage} style={{ backgroundImage: `url(${item.image})` }} />
		</div>
	);
}

export default InstagramItem;
