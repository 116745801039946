import React, { useState, useEffect } from 'react';
import {
	Grid,
	Typography,
	Card,
} from '@material-ui/core';
import useStyles from './style';
import { SortTable, DeleteModal } from './components';
import chat from '../../../../apis/chat';
import Pagination from '@material-ui/lab/Pagination';
import { useToasts } from 'react-toast-notifications';

const ChatHistory = (props) => {
	const classes = useStyles();
	const {
		history, data, selectedCount,
		sortOption,
		requestSort,
		searchTopic, setSearchTopic,
		searchDate, setSearchDate,
		page, setPage,
		total,
		countList, setCountList,
		selectedItem,
		openModal,
		handleDelete,
		handleCloseModal,
		handleSelectedItem,

	} = props;

	return (
		<div className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant="subtitle1" gutterBottom color="secondary">Historia czatów</Typography>
				</Grid>
				<Grid item xs={12}>
					<Card className={classes.table}>
						<SortTable
							rows={data}
							requestSort={requestSort}
							sortOrder={sortOption.sortOrder}
							sortBy={sortOption.sortBy}
							selectedCount={selectedCount}
							searchTopic={searchTopic}
							setSearchTopic={setSearchTopic}
							searchDate={searchDate}
							setSearchDate={setSearchDate}
							handleDelete={handleSelectedItem}
						/>
						<div className={classes.pagination}>
							<Pagination
								className={classes.pagenation_class}
								count={total % selectedCount == 0 ? total / selectedCount : parseInt(total / selectedCount) + 1}
								onChange={(e, page) => { setPage(page) }}
								page={page}
								showFirstButton
								showLastButton />
						</div>
					</Card>
					<DeleteModal
						openModal={openModal}
						handleClose={handleCloseModal}
						handleDelete={handleDelete}
						selectedIndex={selectedItem}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default ChatHistory;
