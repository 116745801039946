import React, { useState } from 'react';
import {
	Grid,
	Typography,
	Button,
	Avatar,
	Divider,
	CircularProgress,
} from '@material-ui/core';
import useStyles from './style';
import { SingleSelect } from 'components/'

const Account = (props) => {
	const classes = useStyles();
	const {
		countryList, languageList, provinceList, positionList,
		selectedCountry, setSelectedCountry,
		selectedLanguage, setSelectedLanguage,
		selectedProvince, setSelectedProvince,
		selectedPosition, setSelectedPosition,
		input, setInput,
		error, setError,
		tryUpdate, setTryUpdate, progressStatus,
		handleUpdateProfile, handleDeleteAccount,
		handleUpload,
	} = props;

	const handleChange = (event) => {
		let arr = JSON.parse(JSON.stringify(input));
		arr[event.target.name] = event.target.value;
		setInput(arr);
	}

	return (
		<>
			<div className={classes.root}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography variant="subtitle1" gutterBottom>Twoje konto</Typography>
					</Grid>
					<Grid container item xs={10} spacing={1}>
						<Grid item xs={12}>
							<div className={classes.input_box_label}><label htmlFor="firstName">Imię<span className={classes.require}>*</span></label></div>
							<input className={classes.input_box} type="text" value={input.firstName} name="firstName" id="firstName" onChange={handleChange} autoComplete='off' />
							<div className={classes.error_log}>{tryUpdate && error["firstName"] && error["firstName"].length > 0 && error.firstName}</div>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.input_box_label}><label htmlFor="lastName">Nazwisko<span className={classes.require}>*</span></label></div>
							<input className={classes.input_box} type="text" value={input.lastName} name="lastName" id="lastName" onChange={handleChange} autoComplete='off' />
							<div className={classes.error_log}>{tryUpdate && error["lastName"] && error["lastName"].length > 0 && error.lastName}</div>
						</Grid>
					</Grid>
					<Grid container item xs={2} alignItems="center" justify="center">
						<Avatar src={input.uploadFile.length > 0 ? input.uploadFile: input.avatarUrl} className={classes.logoAvatar} />
					</Grid>
					<Grid container item xs={12} alignItems="flex-end">
						<Grid item xs={10}>
							<div className={classes.input_box_label}><label htmlFor="uploadPath">Zdjęcie/Logo Firmy</label></div>
							<input className={classes.input_box} type="text" value={input.uploadFile.length > 0 ? input.uploadFile: input.fileName} name="fileName" id="fileName" onChange={handleChange} disabled autoComplete='off' />
						</Grid>
						<Grid item xs={2}>
							<input accept="image/*"	className={classes.input_upload}	id="file_upload" type="file" onChange={handleUpload} />
							<label htmlFor="file_upload">
								<Button variant="contained" color="secondary" component="span" className={classes.btCommon} >
									Wybierz plik
								</Button>
							</label>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Divider className={classes.divider} flexItem />
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="country">Państwo<span className={classes.require}>*</span></label></div>
						<SingleSelect value={selectedCountry} handleChange={setSelectedCountry} list={countryList} />
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="language">Język<span className={classes.require}>*</span></label></div>
						<SingleSelect value={selectedLanguage} handleChange={setSelectedLanguage} list={languageList} />
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<div className={classes.input_box_label}><label htmlFor="province">Województwo</label></div>
								<SingleSelect value={selectedProvince} handleChange={setSelectedProvince} list={provinceList} />
							</Grid>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="city">Miasto</label></div>
								<input className={classes.input_box} type="text" value={input.city} name="city" id="city" onChange={handleChange} autoComplete='off' />
							</Grid>
							<Grid item xs={2}>
								<div className={classes.input_box_label}><label htmlFor="upload">Kod pocztowy</label></div>
								<input className={classes.input_box} type="text" value={input.postCode} name="postCode" id="postCode" onChange={handleChange} autoComplete='off' />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="phoneNumber">Telefon kontaktowy</label></div>
								<input className={classes.input_box} type="text" value={input.phoneNumber} name="phoneNumber" id="phoneNumber" onChange={handleChange} autoComplete='off' />
							</Grid>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="businessPhone">Telefon służbowy</label></div>
								<input className={classes.input_box} type="text" value={input.businessPhone} name="businessPhone" id="businessPhone" onChange={handleChange} autoComplete='off' />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="accountType">Typ konta</label></div>
								<input className={classes.input_box} type="text" value={input.accountType} name="accountType" id="accountType" onChange={handleChange} autoComplete='off' />
							</Grid>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="companyName">Nazwa firmy</label></div>
								<input className={classes.input_box} type="text" value={input.companyName} name="companyName" id="companyName" onChange={handleChange} autoComplete='off' />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="position">Stanowisko</label></div>
								<SingleSelect value={selectedPosition} handleChange={setSelectedPosition} list={positionList} />
							</Grid>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="companyWebsite">Strona internetowa firmy</label></div>
								<input className={classes.input_box} type="text" value={input.companyWebsite} name="companyWebsite" id="companyWebsite" onChange={handleChange} autoComplete='off' />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="taxNumber">NIP Firmy</label></div>
								<input className={classes.input_box} type="text" value={input.taxNumber} name="taxNumber" id="taxNumber" onChange={handleChange} autoComplete='off' />
							</Grid>
							<Grid item xs={6}>
								<div className={classes.input_box_label}><label htmlFor="companyEmailAddress">Adres e-mail firmy</label></div>
								<input className={classes.input_box} type="text" value={input.companyEmailAddress} name="companyEmailAddress" id="companyEmailAddress" onChange={handleChange} autoComplete='off' />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Divider className={classes.divider} flexItem />
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="eamil">Adres e-mail<span className={classes.require}>*</span></label></div>
						<input className={classes.input_box} type="text" value={input.email} name="email" id="email" onChange={handleChange} autoComplete='off' />
						<div className={classes.error_log}>{tryUpdate && error["email"] && error["email"].length > 0 && error.email}</div>
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="currentPassword">Zmiana hasła = podaj obecne hasło</label></div>
						<input className={classes.input_box} type="password" value={input.currentPassword} name="currentPassword" id="currentPassword" onChange={handleChange} autoComplete='off' />
						<div className={classes.error_log}>{tryUpdate && error["currentPassword"] && (input.newPassword && input.newPassword.length > 0 || input.confirmPassword && input.confirmPassword.length > 0) && error.currentPassword}</div>
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="newPassword">Podaj nowe hasło</label></div>
						<input className={classes.input_box} type="password" value={input.newPassword} name="newPassword" id="newPassword" onChange={handleChange} autoComplete='off' />
						<div className={classes.error_log}>{tryUpdate && error["newPassword"] && (input.currentPassword || input.currentPassword && input.currentPassword.length > 0) && error.newPassword}</div>
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="confirmPassword">Powtórz nowe hasło</label></div>
						<input className={classes.input_box} type="password" value={input.confirmPassword} name="confirmPassword" id="confirmPassword" onChange={handleChange} autoComplete='off' />
						<div className={classes.error_log}>{tryUpdate && error["confirmPassword"] && (input.currentPassword || input.currentPassword && input.currentPassword.length > 0) && (input.newPassword || input.newPassword && input.newPassword.length > 0) && error.confirmPassword}</div>
					</Grid>

					<Grid item xs={12}>
						<Divider className={classes.divider} flexItem />
					</Grid>

					<Grid item xs={12}>
						<div className={classes.input_box_label}><label htmlFor="preference">Preferencje eksportowanych projektow<span className={classes.require}>*</span></label></div>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify="space-between">
							<Grid item xs={3}>
								<Button variant="contained" color="secondary" className={classes.btCommon} onClick={handleDeleteAccount}>
									Usuń konto
							</Button>
							</Grid>
							<Grid item xs={4}>
								<Button variant="contained" color="secondary" className={classes.btCommon} onClick={handleUpdateProfile}>
									Zaktualizuj informacje
							</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{
				progressStatus ?
					<>
						<div className={classes.progressContainer}>
							<CircularProgress className={classes.progress} />
						</div>
					</>
					:
					<></>
			}
		</>
	);
};

export default Account;
