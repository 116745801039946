import React, { useState } from 'react';
import { Grid, Typography, TextField, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import { SingleSelect } from 'components'
import { HistoryProjectItem } from './components'
import historyImage from 'assets/img/recent_project.png';

const sortList = [
	{ id: 1, name: 'Data powstania rosnaco' },
	{ id: 2, name: 'Data pawstania malejaco' },
	{ id: 3, name: 'Data aktualizaji rosnaco' },
	{ id: 4, name: 'Data aktualizaji malejaco' },
	{ id: 5, name: 'Nazwa A-Z' },
	{ id: 6, name: 'Nazwa Z-A' },
]

const historyProjectList = [
	{ id: 1, placeName: 'ul.Wandy we Wroctawiu', date: '05.01.2021', counter: 12, image: historyImage},
	{ id: 2, placeName: 'Rndo Powstancow Slaskich', date: '05.03.2021', counter: 4, image: historyImage},
	{ id: 3, placeName: 'Sky Tower', date: '05.03.2021', counter: 19, image: historyImage},
	{ id: 4, placeName: 'Wroctawski Rynek', date: '05.04.2021', counter: 2, image: historyImage},
	{ id: 5, placeName: 'Business Garden', date: '05.05.2021', counter: 9, image: historyImage},
	{ id: 6, placeName: 'Business Garden', date: '05.05.2021', counter: 9, image: historyImage},
	{ id: 7, placeName: 'ul.Wandy we Wroctawiu', date: '05.01.2021', counter: 12, image: historyImage},
	{ id: 8, placeName: 'Rndo Powstancow Slaskich', date: '05.03.2021', counter: 4, image: historyImage},
	{ id: 9, placeName: 'Sky Tower', date: '05.03.2021', counter: 19, image: historyImage},
	{ id: 10, placeName: 'Wroctawski Rynek', date: '05.04.2021', counter: 2, image: historyImage},
	{ id: 11, placeName: 'Business Garden', date: '05.05.2021', counter: 9, image: historyImage},
	{ id: 12, placeName: 'Business Garden', date: '05.05.2021', counter: 9, image: historyImage},
]

const Project = () => {
	const classes = useStyles();
	const history = useHistory();
	const [sortValue, setSortValue] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const handleClickNewProject = () => {
		history.push('/project')
	}
	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item sm={12}>
					<Typography variant="h5">Twoje Projekty</Typography>
				</Grid>
				<Grid container item justify="space-between" alignItems="center">
					<div className={classes.lineContainer}>
						<Typography variant="body1">10&nbsp;Projektow</Typography>
						<Button className={classes.btnProject} onClick={handleClickNewProject} variant="contained" color="secondary">Nowy projekt</Button>
						<TextField id="serachName" className={classes.searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Szukay" variant="outlined" name="searchValue" value={searchValue} />
					</div>
					<div className={classes.lineContainer}>
						<Typography variant="body1">Sortuj według:</Typography>
						<SingleSelect value={sortValue} handleChange={setSortValue} list={sortList} />
						<Button className={classes.btnSearch} variant="contained" color="secondary">Sortuj</Button>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				{historyProjectList.map((item, index) => (
					<Grid key={index} item lg={3} md={4} sm={6} xs={12}>
						<HistoryProjectItem item={item} />
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default Project;
