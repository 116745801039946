import React from "react";
import { polarToCartesian } from "./geometry";

const CameraEnclosure = ({fillColor, strokeColor}) => (
  <circle
    cx="0"
    cy="0"
    r="20"
    fill={fillColor}
    stroke={strokeColor}
    strokeWidth={2}
  />
);

const CameraBody = ({strokeColor}) => (
  <path
    transform="translate(-10, -10)"
    d="M0,0 h20 l-5,5 h5 v15 h-20 v-15 h5 Z"
    fill={strokeColor}
  />
);

const FieldOfView = ({ angle, range, fillColor, strokeColor }) => {
  const arcStart = polarToCartesian(range, -angle / 2);
  const arcEnd = polarToCartesian(range, angle / 2);
  const largeArcFlag = angle <= 180 ? "0" : "1";
  return (
    <path
      d={`
        M0,0
        L${arcStart.x},${arcStart.y}
        A${range},${range},0,${largeArcFlag},1,${arcEnd.x},${arcEnd.y}
        Z
      `}
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth={2}
    />
  );
};

const CircleStartMark = ({angle, range, fillColor, strokeColor}) => {
  const arcStart = polarToCartesian(range, -angle / 2);
  return (
    <circle
        cx={arcStart.x}
        cy={arcStart.y}
        r="10"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={2}
      />
  );
};

const CircleEndMark = ({angle, range, fillColor, strokeColor}) => {
  const arcEnd = polarToCartesian(range, angle / 2);
  return (
    <circle
        cx={arcEnd.x}
        cy={arcEnd.y}
        r="10"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={2}
      />
  );
};

const PersonMark = ({range, fillColor, strokeColor}) => {
  const arcCenter = polarToCartesian(range, 0);
  return (
    <g>
    <circle fill={fillColor} stroke={strokeColor} strokeWidth={2} cx={arcCenter.x} cy={arcCenter.y} r="5"/>
		{/* <path fill={fillColor} d="M40.201,28.096c-1.236-13.887-7.854-16.657-7.854-16.657s-6.396-3.845-13.129,1.052
			c-4.365,3.973-5.373,10.038-6.063,15.896c-0.349,2.977,4.307,2.941,4.653,0c0.412-3.496,1-7.008,2.735-9.999l-0.008,3.375
			l-0.032,16.219v12.867c0,1.383,1.014,2.506,2.438,2.506c1.423,0,2.578-1.123,2.578-2.506V32.457h2.278c0,4.309,0,14.144,0,18.451
			c0,3,4.652,3,4.652,0c0-4.309,0-8.619,0-12.927l0.197-16.251c0.002-1.551,0.004-2.937,0.004-3.901
			c1.859,3.046,2.473,6.664,2.896,10.265C35.895,31.037,40.55,31.073,40.201,28.096z"/> */}
    </g>
		
  );
};

const Camera = ({ x, y, angle, fov, range, fillColor, strokeColor}) => (
  <g transform={`translate(${x}, ${y}) rotate(${angle})`}>
    <CameraEnclosure fillColor={fillColor} strokeColor={strokeColor}/>
    <CameraBody strokeColor={strokeColor} />
    <FieldOfView angle={fov} range={range} fillColor={fillColor} strokeColor={strokeColor} />
    <CircleStartMark angle={fov} range={range} fillColor={fillColor} strokeColor={strokeColor} />
    <CircleEndMark angle={fov} range={range} fillColor={fillColor} strokeColor={strokeColor} />
    <PersonMark angle={fov} range={range} fillColor={fillColor} strokeColor={strokeColor} />
  </g>
);

export default Camera;
