import React from 'react';
import useStyles from './style';
import { Grid } from '@material-ui/core'
import { RecentProject, LatestNews } from './components'

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
			<Grid container>
				<Grid item lg={7} sm={12} xl={7} xs={12}>					
					<RecentProject />
				</Grid>
				<Grid item lg={5} sm={12} xl={5} xs={12}>
					<LatestNews />
				</Grid>
			</Grid>
    </div>
  );
};

export default Dashboard;
