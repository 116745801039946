import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		// padding: theme.spacing(3),
		// color: theme.palette.text.primary,
		// backgroundColor: theme.palette.white,
		// height: 'calc(100vh - 80px)',
		// overflow: 'auto',
	},
  avatar: {
    backgroundColor: theme.palette.secondary.light,
    height: 50,
    width: 50,
  },
  messageContent: {
    fontSize: '13px',
    fontFamily: 'Roboto',
    border: '1px solid #eaeaea',
    padding: '8px',
    borderRadius: '4px',
    lineHeight: '1.5em',
    maxWidth: 'fit-content',
  }
}));

export default useStyles;
