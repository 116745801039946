class constants {
    CHECK_ALL_FIELDS = "Proszę uzupełnić wszystkie pola.";
    ENTER_VALID_EMAIL = "Proszę wprowadzić poprawny adres e-mail.";
    CHECK_VALID_PASSWORD = "Hasło musi mmieć co namniej 6 znaków.";		
    ENTER_SAME_PASSWORD = "Hasła muszą być takie same.";
		ENTER_FIRSTNAME = "Enter first name";
		ENTER_LASTNAME = "Enter last name";
    CHECK_POLICY = "Proszę zaakceptować regulamin.";
		GOOGLE_LOGIN_FAIL = "Google login failure";
		unauthenticated_url = [
			'/not-found',
			'/login',
			'/register',
			'/forgotpassword',
			'/verification',
			'/reset_password',
			'/validate',
	];
}

export default new constants();
