import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0),
		backgroundColor: theme.palette.black_white,
		color: theme.palette.color,
		fontFamily: 'roboto',
		minHeight: '100vh',
		position: 'relative',
	},
	content: {
		width: '100%',
	},
	main: {
		width: '100%',
		marginTop: '80px',
		height: 'calc(100vh - 80px)',
		overflow: 'auto',
		position: 'absolute',
	},
}));

export default useStyles;
