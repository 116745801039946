import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		// padding: theme.spacing(3),
		// color: theme.palette.text.primary,
		// backgroundColor: theme.palette.white,
		// height: 'calc(100vh - 80px)',
		// overflow: 'auto',
	},
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 50,
    width: 50,
  },
  messageContent: {
    fontSize: '13px',
    fontFamily: 'Roboto',    
    padding: '8px',
    borderRadius: '4px',
    lineHeight: '1.5em',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    maxWidth: 'fit-content',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default useStyles;
