import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {    
    padding: theme.spacing(3),
		// paddingTop: theme.spacing(10),
		color: theme.palette.text.primary
  },	
	fullwidth: {
		width: '100%',
	},
	btnSend: {
    '& .MuiButton-label': {
      textTransform: 'none',
      fontSize: '1.0625em',
    },
    '&:hover': {
      backgroundColor: theme.palette.topbar_background
    },
    padding: theme.spacing(0.5, 4),
    borderRadius: '0px',
    fontWeight: '300',
    backgroundColor: theme.palette.topbar_background,
    color: theme.palette.black_white,    
		width: '100%'
  },
}));

export default useStyles;
