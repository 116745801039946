import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		// padding: theme.spacing(0),
		// color: theme.palette.text.primary,
		'& .leaflet-container': {
			width: '100%',
			height: '100vh',
		},
		'& .sr-only': {
			// display: 'none',
		},
	},
	mapContainer: {
	},
	mapStyles: {
		width: '100%',
		height: '100%',
	},
	mainContainer: {
		padding: 16,
	},
	btnSaveChange: {
		width: '100%',
		textTransform: 'none',
	},
	btnAddCamera: {
		width: '100%',
		textTransform: 'none',
		// minWidth: 190,
	},
	inputProjectName: {
		width: '100%',
		backgroundColor: theme.palette.white,
		'& .MuiOutlinedInput-input': {
			padding: '11px 14px',
		},
	},
	nonActive: {
		width: 30,
		height: 30,
		minWidth: 30,
		marginTop: 12,
		marginLeft: 12,
		fontSize: 16,
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		color: theme.palette.secondary.main,
		'& .MuiButton-startIcon': {
			marginLeft: 0,
			marginRight: 0,
		}
	},
	active: {
		width: 30,
		height: 30,
		minWidth: 30,
		marginTop: 12,
		marginLeft: 12,
		fontSize: 16,
		color: theme.palette.white,
		backgroundColor: theme.palette.secondary.main,
		'& .MuiButton-startIcon': {
			marginLeft: 0,
			marginRight: 0,
		},
		'&:hover': {
			backgroundColor: theme.palette.secondary.light,
		},
	},
}));

export default useStyles;
