import React from 'react';
import useStyles from './style';
import { Typography, IconButton } from '@material-ui/core';

const YoutubeItem = props => {
	const classes = useStyles();
	const { item } = props

	return (
		<div className={classes.root}>
			<div className={classes.backgroundImage} style={{ backgroundImage: `url(${item.image})` }} />
			<Typography variant="h6" className={classes.title}>{item.title}</Typography>
			<Typography variant="body2" className={classes.description}>{item.description}</Typography>
			<div className={classes.readOn}>
				<Typography variant="body2" color="secondary">{item.readOn}</Typography>
			</div>
		</div>
	);
}

export default YoutubeItem;
