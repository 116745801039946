import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {    
    padding: theme.spacing(3),		
		color: theme.palette.text.primary,
  },
	btnProject: {
		marginLeft: 32,
		marginRight: 20,
		fontSize: 12,
		textTransform: 'none',
	},
	lineContainer: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiFormControl-root': {
			width: '200px',
			marginLeft: '10px'
		},
		margin: 16,
		marginTop: 0,
	},
	btnSearch: { 
		marginLeft: 15,
		fontSize: 12,
		textTransform: 'none',
	},
	searchValue: {
		'& .MuiOutlinedInput-input': {
			padding: '9px 10px',
		}
	},
}));

export default useStyles;
