import axios from 'axios';
import authHeader from './auth-header';
import storage from '../utils/storage';
class Chat {

	getTopicList = () => {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/chat/topic`, {
				headers: authHeader(storage.getStorage('token')),
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
					return response.data;
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}

	getMessageList = (topic) => {
		return axios
			.get(`${process.env.REACT_APP_BACKEND_URL}/chat/message`, {
				headers: authHeader(storage.getStorage('token')),
				params:{topic: topic},
			})
			.then(response => {
				if (response.data.code === 401) {
					storage.removeStorage('token');
					storage.removeStorage('role');
					return response.data;
				} else if (response.data.code === 200) {
					return response.data;
				}
			}).catch(error => {
				return error;
			})
	}

	sendMessage = (topic, to, content) => {
		return axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/chat/send`, {
				topic: topic,
				to: to,
				content: content,
			}, {
				headers: authHeader(storage.getStorage('token'))
			}).then(response => {
				if (response.data.code === 401)
					storage.removeStorage('token');
				return response.data;
			}).catch(error => {
				return error;
			})
	}

	sendFeedback = (topic, level, opinion) => {
		return axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/chat/feedback`, {
				topic: topic,
				feeling_status: level,
				content: opinion,
			}, {
				headers: authHeader(storage.getStorage('token'))
			}).then(response => {
				if (response.data.code === 401)
					storage.removeStorage('token');
				return response.data;
			}).catch(error => {
				return error;
			})
	}
	
	getListByOption = (sort_column, sort_order, count, page, searchTopic,  searchDate) => {
		return axios
			.post(`${process.env.REACT_APP_BACKEND_URL}/chat/getListByOption`, {
				sort_column: sort_column,
				sort_order: sort_order,
				count: count,
				page: page,
				searchTopic: searchTopic,
				searchDate: searchDate,
			}, {
				headers: authHeader(storage.getStorage('token'))
			})
			.then(response => {
				if (response.data.code === 401)
					storage.removeStorage('token');
				return response.data;
			}).catch(error => {
				return error;
			})
	}

	delete = (id) => {
		return axios
			.delete(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
				headers: authHeader(storage.getStorage('token')),
				params: {
					id: id
				},
			})
			.then(response => {
				if (response.data.code === 401)
					storage.removeStorage('token');
				return response.data;
			}).catch(error => {
				return error;
			})
	}
}
export default new Chat();
