import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		color: theme.palette.text.primary,
		height: 'calc(100vh - 80px)',
		overflow: 'auto',
	},
	headTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 10,
	},
	headInstagram: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	videoGuide: {
		borderRadius: 4,
	}
}));

export default useStyles;
