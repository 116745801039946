import React, { useState } from 'react';
import {
	Grid, Typography,
} from '@material-ui/core';
import useStyles from './style';
import recentImage from 'assets/img/recent_project.png';
import cameraImage from 'assets/img/camera.jfif';
import videSource from 'assets/img/video.mp4';
import { YoutubeItem, InstagramItem } from './components';

const postedList = [
	{ id: 1, image: recentImage, readOn: 'Czytaj dalej', title: 'Lorem ipsum dolor sit amet', description: 'Consectetur adipiscing elit, sed doeiusmod tempor incididunt ut laboreet dolore magna aliqua...'},
	{ id: 2, image: recentImage, readOn: 'Czytaj dalej', title: 'Lorem ipsum dolor sit amet', description: 'Consectetur adipiscing elit, sed doeiusmod tempor incididunt ut laboreet dolore magna aliqua...'},
]

const instagramList = [
	{ id: 1, image: cameraImage },
	{ id: 2, image: cameraImage },
	{ id: 3, image: cameraImage },
	{ id: 4, image: cameraImage },
	{ id: 5, image: cameraImage },
]

const LatestNews = () => {
	const classes = useStyles();
	
	return (
		<div className={classes.root}>
			<div className={classes.headTitle}>
				<Typography variant="subtitle2">Ostatnie aktualnosci</Typography>
				<Typography variant="body2" color="secondary">Zobacz wiecej</Typography>
			</div>
			<Grid container spacing={3}>
				{postedList.map((item, index) => (
					<Grid item key={index} lg={6} sm={12} xs={12}>
						<YoutubeItem item={item} />
					</Grid>
				))}
			</Grid>
			<div className={classes.headInstagram}>
				<Typography variant="subtitle2">Obserwuj nas na Instagramie</Typography>
			</div>
			<Grid container spacing={3}>
				{instagramList.map((item, index) => (
					<Grid item lg={3} sm={6} xs={6} key={index}>
						<InstagramItem item={item}/>
					</Grid>
				))}
			</Grid>
			<div className={classes.headInstagram}>
				<Typography variant="subtitle2">Videoporadnik</Typography>
			</div>
			<div>
				<video controls width="100%" autoPlay className={classes.videoGuide}>
					<source src={videSource} type="video/mp4"/>
				</video>
			</div>

		</div>
	);
};

export default LatestNews;
