import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {    
    // padding: theme.spacing(2),
		color: theme.palette.text.primary
  },	
}));

export default useStyles;
