import React, { useEffect, useState } from 'react';
import {
	Grid,
} from '@material-ui/core';
import useStyles from './style';
import { ChatTopic, ChatHistory } from './components'
import chat from '../../apis/chat'
import { useToasts } from 'react-toast-notifications'

const tempTopicHistory = [
	{ id: 1, topic: 'topic1', updated_at: '2020-01-01' },
  { id: 2, topic: 'topic2', updated_at: '2020-01-02' },
  { id: 3, topic: 'topic3', updated_at: '2020-01-03' },
]

const Chat = (props) => {
	const classes = useStyles();
	const { history } = props;
	const [messageList, setMessageList] = useState([]);
	const [topicList, setTopicList] = useState([]);
	const [topic, setTopic] = useState(0);

	const [sortOption, setSortOption] = useState({ sortBy: 0, sortOrder: "asc" });
  const [countList, setCountList] = useState([25, 50, 100]);
  const [selectedCount, setSelectedCount] = useState(10);
	const [searchDate, setSearchDate] = useState(new Date('2020-01-01'));
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchTopic, setSearchTopic] = useState('');
  const [selectedItem, setSelectedItem] = useState(-1);
  const [progressStatus, setProgressStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
	const { addToast } = useToasts()

	/* chat message */
	useEffect(() => {
		chat
			.getTopicList()
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else if (response.code === 200) {
					setTopicList(response.data.list)
				}
			})
	}, []);

	useEffect(() => {
		if (topicList.length > 0) {
			setTopic(1);
		}
	}, [topicList]);

	useEffect(() => {
		chat
			.getMessageList(topic)
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else if (response.code === 200) {
					setMessageList(response.data.list)
				}
			})
	},[topic])

	const handleSendMessage = (to, topic, content) => {
		chat
			.sendMessage(topic, to, content)
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else if (response.code === 200) {
					let _messageList = JSON.parse(JSON.stringify(messageList));

					_messageList.push({ topic: topic, to: to, content: content });
					setMessageList(_messageList);
				}
			})
	}

	const handleCreateFeedback = (topic, level, opinion) => {
		chat
		.sendFeedback(topic, level, opinion)
		.then(response => {
			if (response.code === 401) {
				history.push('/login');
			} else if (response.code === 200) {
				addToast(<label>{response.message}</label>, { appearance: response.code === 200 ? 'success' : 'error', autoDismissTimeout: response.code === 200 ? 1000 : 3000, autoDismiss: true })
			}
		})	
	}
	/* chat history */
	useEffect(() => {
		handleSearch();
		setPage(1);
	}, [selectedCount, searchTopic, searchDate]);

	useEffect(() => {
    handleSearch();
  }, [sortOption, page]);

	const handleSelectedItem = (id) => {
    setSelectedItem(id);
    setOpenModal(true);
  }

	const requestSort = (pSortBy) => {
		var sortOrder = "asc";
		if (pSortBy === sortOption.sortBy) {
			sortOrder = (sortOption.sortOrder === "asc" ? "desc" : "asc");
		}
		setSortOption({ sortBy: pSortBy, sortOrder: sortOrder })
	}

	const handleSearch = () => {
    chat
      .getListByOption(sortOption.sortBy, sortOption.sortOrder, selectedCount, page, searchTopic,  searchDate)
      .then(response => {
        if (response.code === 401) {
          history.push('/login');
        } else {
          // if (response.code === 200) {
            // setData(response.data.topicHistory);
            // setTotal(response.data.count);
						setData(tempTopicHistory);
            setTotal(3);
          // }
        }
      })
  }

	const handleCloseModal = () => {
		setOpenModal(false);
	}

	const handleDelete = () => {
		setProgressStatus(true);
		chat
			.delete(selectedItem)
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else {
					if (response.code === 200) {
						addToast(<label>{response.message}</label>, { appearance: response.code === 200 ? 'success' : 'error', autoDismissTimeout: response.code === 200 ? 1000 : 3000, autoDismiss: true })
					}
					setProgressStatus(false);
					handleSearch();
					setPage(1);
				}
			})
	}

	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item lg={7} xl={7} sm={12} xs={12}>
					<ChatTopic topicList={topicList} topic={topic} handleChangeTopic={setTopic} messageList={messageList} handleSendMessage={handleSendMessage} handleCreateFeedback={handleCreateFeedback} />
				</Grid>
				<Grid item lg={5} xl={5} sm={12} xs={12}>
					<ChatHistory
						data={data}
						requestSort={requestSort}
						sortOption={sortOption}
						selectedCount={selectedCount}
						searchTopic={searchTopic}
						setSearchTopic={setSearchTopic}
						searchDate={searchDate}
						setSearchDat={setSearchDate}
						handleSelectedItem={handleSelectedItem}
						handleCloseModal={handleCloseModal}
						handleDelete={handleDelete}
						openModal={openModal}
						total={total}
						page={page}
						setPage={setPage}
						selectedItem={selectedItem}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default Chat;
